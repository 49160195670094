<template>
    <div class="trainingSclool">
        <!-- 顶部banner图 -->
        <div class="top_banner">
            <el-carousel :interval="5000" >
                <el-carousel-item v-for="(item,idx) in schoolBannerList" :key="idx">
                    <img :src="item.img" alt="">
                    <!-- 轮播图查看详情 -->
                    <div class="banner_title">
                        <div class="title_text">{{item.title}}</div>
                        <div class="title_line"></div>
                        <div class="title_tips">{{item.tips}}</div>
                    </div>
                    <div class="show_detail" :class="{'show_detail_bgc':idx==0,'show_detail_bgc2':idx==1,'show_detail_bgc3':idx==2,}" @click="showMoreCourse(item.idx,item.title)">
                        查看详情
                        <img src="@/assets/images/school/show_detail.png" alt="" srcset="">
                    </div>
                </el-carousel-item>
            </el-carousel>
            <!-- 全部课程分类 -->
            <div class=" all_course_categary" >
                <div class="course_categary">
                    <div class="wrap">
                        <div class="course_categary_title">
                            <div class="icon">
                                <img src="@/assets/images/school/category.png" alt="">
                            </div>
                            <div class="title">全部分类课程</div>
                        </div>
                        <div class="category_main">
                            <div class="category_content" v-for="(item,idx) in filterCourseClassData" :key="idx">
                                <div class="con_title">{{item.name}}</div>
                                <div class="con_text" >
                                    <div class="text_right" v-for="(val,inx) in item.children" :key="inx" @click="toCourseDetails(idx,inx,val.name)">{{val.name}} </div>
                                </div>
                            </div>
                        </div>
                        <div class="category_bottom" @click="showMoreCourse(0,0)">
                            查看更多 
                            <img src="@/assets/images/school/icon_right.png" alt="">
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- 国家职业预约咨询 -->
        <div class="appointment">
            <div class=" flex JustifyContentSB AlignItemsC ">
                <div class="left flex">
                    <div class="b_title">培考通 · </div>
                    <div class="occupation">国家职业</div>
                </div>
                <div class="right flex JustifyContentC AlignItemsC" @click='appointenting'>
                    <img src="@/assets/images/school/appointent@2x.png" alt="">
                    预约咨询
                </div>
            </div>
        </div>
        <!-- 行业动态 -->
        <dynamic :dynamicList= 'dynamicList' :showMore= 'showMore'></dynamic>
        <!-- 合作院校 -->
        <div class="partnerSchool ">
             <div class="dy_title flex JustifyContentSB">
                <div class="titte_before">合作院校</div>
            </div>
            <div class="partnerSchool_img">
                <img src="https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/pannerSchool.png" alt="" srcset="">
            </div>
        </div>
        <!-- 分类列表 -->
        <category :categoryList="firstCategoryList" :courseData='courseData'></category>
        <!-- 课程视频列表 -->
        <videoModule :iframeForm='iframeForm' :iframeList='iframeList'></videoModule>
         <!-- 预约咨询弹窗 -->
        <appointmentCon @closeDialog='clsoeDialog' :appointmentTitle='appointmentTitle' :appointmentForm = 'appointmentForm' :appointmentVisiable='appointmentVisiable'></appointmentCon>
    </div>
</template>

<script>
import dynamic from '../../components/trainingComponent/dynamic.vue';
import appointmentCon from '../../components/appointmentCon.vue';
import category from '../../components/trainingComponent/category.vue';
import videoModule from '../../components/trainingComponent/videoModule.vue'
import courseClass from '../../assets/jsons/courseClass.json'
import courseData from '../../assets/jsons/courseData.json'
export default {
    components:{
        dynamic,
        appointmentCon,
        category,
        videoModule
    },
    data(){
        return {
            // 分类列表
            courseClassData: courseClass,
            // 分类列表详情数据
            courseData: courseData,
            // 轮播图列表
            schoolBannerList:[
                {
                    idx:8,
                    title:"家政服务",
                    tips:"保洁 育婴 养老",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/schoolBanner.png',
                },
                {   
                    idx:10,
                    title:"餐饮",
                    tips:"西点 西餐 中点 中餐",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/schoolBanner3.png',
                },
                {   
                    idx:9,
                    title:"生活服务",
                    tips:"保健 美发 美容",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/schoolBanner2.png',
                }
            ],
            // 分类列表
            firstCategoryList:[
                {   
                    idx:8,
                    title:"家政服务",
                    name:"家政服务类",
                    mark:"保洁 育婴 养老",
                    color:"linear-gradient(157deg, #74E1FF 0%, #5077FF 100%)",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/housekeeping.png',
                    // tabList:['全部','保洁','保育','育婴','家用电器','家政','养老'],
                    tabList:[
                        // {
                        //     name:"全部",
                        //     color:true
                        // }
                    ],
                    conList:[],
                },
                {   
                    idx:9,
                    title:"生活服务",
                    name:'生活服务类',
                    mark:"保健 美发 美容",
                    color:"linear-gradient(135deg, #FFA8C1 0%, #FF48B3 100%)",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/serve.png',
                    tabList:[
                        // {
                        //     name:"全部",
                        //     color:true
                        // }
                    ],
                    conList:[],
                },
                {   
                    idx:10,
                    title:"餐饮",
                    name:'餐饮类',
                    mark:"西点 西餐 中点",
                    color:"linear-gradient(135deg, #E7BA99 0%, #D68039 100%)",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/foods.png',
                    tabList:[
                        // {
                        //     name:"全部",
                        //     color:true
                        // }
                    ],
                    conList:[],
                }
            ],
            showMore:'show',
            // 行业动态列表
            dynamicList:[
                {
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/dynamicImg.jpeg",
                    title:"关于做好2021年度技工院校等统计工作的通知",
                    district:"各省、自治区、直辖市及新疆生产建设兵团人力资源社会保障厅（局）：为切实做好2021年度技工院校、就业训练中心、民办培训机构等统计工作，现就有关事项通知如下：一、关于技工院校等统计年报（一）根据《人力资源社会保障部关于印发人力资源和社会保障统计调查制度的通知》（人社部发〔2021〕100号）有关要求，2021年度统计年报使用《人力资源和社会保障统计调查制度》中的《技工院校综合情况表》（表号：人社统OS1号，见附件1）、《就业训练中心综合情况表》（表号：人社统OS2号，见附件2）、《民办培训机构综合情况表》（表号：人社统OS3号，见附件3），上述报表的报告期均为上年12月1日至本年11月30日，报表结构采用2021年版本，",
                    link:"https://mp.weixin.qq.com/s/1vawrfTb72_8X07lZi_Lig"
                },
                {   
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/dynamicImg2.jpeg",
                    title:"产教综合体：产教融合的创新平台",
                    district:"国务院办公厅《关于深化产教融合的若干意见》明确指出，“应鼓励区域、行业骨干企业联合职业学校、高等学校共同组建产教融合集团（联盟），带动中小企业参与，推进实体化运作。”针对企业参与渠道不通畅的问题，如何通过实体化运作，搭建具有校企平等话语权的平台，增强企业参与动力，是实现产与教真“融”真“合”的关键。",
                    link:"https://mp.weixin.qq.com/s/8QNdgewDMcOlSrQVIOR86A"
                },
                {   
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/dynamicImg3.jpeg",
                    title:"2021年版《国家职业资格目录》出炉，目录全文+权威解读，大变化！",
                    district:"2021年版国家职业资格目录共计72项职业资格。其中，专业技术人员职业资格59项，含准入类33项，水平评价类26项技能人员职业资格13项。与2017年版相比，《目录》有哪些变化？",
                    link:"https://mp.weixin.qq.com/s/egZ6BmwcuJFnYbd4Pb1Zig"
                },
                {   
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/dynamicImg4.jpeg",
                    title:"发改委关于推动生活性服务业补短板上水平提高人民生活品质若干意见的通知",
                    district:"各省、自治区、直辖市人民政府，国务院各部委、各直属机构：国家发展改革委《关于推动生活性服务业补短板上水平提高人民生活品质的若干意见》已经国务院同意，现转发给你们，请认真贯彻落实。",
                    link:"http://www.gov.cn/zhengce/content/2021-11/02/content_5648192.htm"
                },
                
            ],
            // 预约咨询
            appointmentVisiable:false,
            appointmentForm:{},
            appointmentTitle:"",
            // 分类列表
            filterCourseClassData:[],
            iframeList: [
                {
                    title: "保育员",
                    text:
                        "通过本课程可以系统地掌握现代儿童保健科学及儿童教育理论知识，并能根据婴幼儿生理、心理发展的特点及保教工作的目标、原则、方法，独立地开展婴幼儿的各项保育工作。",
                    src_mp4:
                        "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/1.mp4",
                    img_src: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/1.png'
                },
                {
                    title: "养老护理员四(中)级",
                    text:"本课程立足于国家职业标准，提升从事养老护理工作人员的理论知识和实操技能。主要内容包括生活基础，基础照护，康复，心理支持等。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/2.mp4",
                    img_src: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/2.png'
                },
                {
                    title: "美容师五(初)级",
                    text:"本课程主要针对准备从事美容行业相关工作岗位的人员，有助于提升理论知识和操作技能，帮助美容师为顾客提供护肤、美体及化妆服务。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/3.mp4",
                    img_src: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/3.png'
                },
                {
                    title: "茶艺师",
                    text:"通过专业化培训能够具备全面的茶及茶文化知识，接待客人、进行茶艺表演、对茶及相关产品进行销售，且具备相应规范化的专业技能。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/4.mp4",
                    img_src: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/4.png'
                },
                {
                    title: "中式烹调师",
                    text:"了解中式烹调师基础理论知识，基础刀工、勺工、翻锅、菜肴制作等相关内容，以及中餐的起源、概念及中餐菜肴特点，认识烹调的作用、学习厨师应具备的职业素质。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/5.mp4",
                    img_src:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/5.png'
                },
                {
                    title: "西式面点师四(中)级",
                    text:"本课程主要学习食品卫生安全、饮食营养知识、各种的蛋糕做法等相关知识，透过本课程能掌握原料配比原理，卫生和营养知识等。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/6.mp4",
                    img_src: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/1D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/6.png'
                },
            ],
            iframeForm:{
                firstTitle:"丰富多元课程",
                secondTitle:"满足职业工种鉴定与上岗需求"
            }

        }
    },
    mounted(){
       this.initData();
    },
    methods:{
        // 获取二级分类
        getSecondCategoryList(){
            // console.log(this.firstCategoryList,'-------分类列表数据');
            this.firstCategoryList.forEach((val,idx)=>{
                this.courseClassData.forEach(item=>{
                    if(val.name == item.name){
                        this.firstCategoryList[idx].tabList = JSON.parse(JSON.stringify(item.children));
                    }
                })
            })
            this.firstCategoryList.forEach((val)=>{
                val.tabList.forEach((item)=>{
                    item.color = false;
                })
                val.tabList.unshift({name:"全部",color:true})
            })
        },
        initData(){
            // console.log(this.courseClassData,'---------this.courseClassData');
            let filterCourseClassData = JSON.parse(JSON.stringify(this.courseClassData));
            this.filterCourseClassData = filterCourseClassData.splice(5,6)
            this.getSecondCategoryList();
            this.getCourseList(0)
            this.getCourseList(1)
            this.getCourseList(2)
        },
        // 获取家政服务类课程
        getCourseList(index){
            let list = JSON.parse(JSON.stringify(this.firstCategoryList[index].tabList))
            list.splice(0,1);
            let listChildrenIds = [];
            list.forEach((val)=>{
                val.children.map(item=>{
                    listChildrenIds.push(item.id)
                })
            })
            this.courseData.forEach(item => {
                listChildrenIds.forEach(val=>{
                    if (val == item.id) {
                        this.firstCategoryList[index].conList.push(item)
                    }
                })
            })
        },
        //点击前往课程详情
        toCourseDetails (idx,inx,name) {
            // console.log(idx,inx,name);
            this.$router.push(
                {
                    path:"/courseCenter",
                    query:{
                        firstIndex:idx,
                        secondIndex:inx,
                        name:name
                    }
                }
            )
        },
        // 查看课程中心
        showMoreCourse(idx,name){
            if(idx){
                this.$router.push(
                    {
                        path:"/courseCenter",
                        query:{
                            firstIndex:idx,
                            name:name
                        }
                    }
                )
            }else{
                this.$router.push('/courseCenter')
            }
            
        },

         // 预约咨询
        appointenting(){
            this.appointmentForm = {};
            this.appointmentTitle = '学校预约咨询';
            this.appointmentForm ={
                name:"",
                major:null,
                city:[],
                contact:'',
                phone:'',
                type:"school"
            }
            this.appointmentVisiable = true;
        },
        // 关闭弹窗
        clsoeDialog(){
            this.appointmentVisiable = false
        },
        // 合作院校更多
        dynamicMore(){

        },
    }
}
</script>

<style lang='scss' scoped>
    ::v-deep{
        .el-carousel__container{
            height: 680px;
        }
    }
@media screen and (min-width: 1560px){
    .trainingSclool{
        width: 100%;
        min-width: 1200px;
        min-height: 800px;
        box-sizing: border-box;
        position: relative;
        background: #FAFAFA;
    }
    // banner图片
    .top_banner{
        position: relative;
    }
    .el-carousel__item {
        img{
            width: 100%;
            height: 680px;
        }
    }
    // 轮播图名称
    .banner_title{
        position:absolute;
        top: 30%;
        left:calc(16% + 240px);
        .title_text{
            font-size: 50px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
        }
        .title_line{
            width: 32px;
            height: 3px;
            background: #FFFFFF;
            margin: 37px 0px;
        }
        .title_tips{
            font-size: 24px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 36px;
        }
    }
    // banner查看详情 
    .show_detail{
        position: absolute;
        bottom: 30%;
        left:calc(16% + 240px);
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 182px;
        height: 56px;
        border-radius: 4px;
        font-size: 24px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        cursor: pointer;
        color: #FFFFFF;
        z-index: 1000000;
        img{
            width: 22px;
            height: 22px;
            margin-left:16px;
        }
    }
    .show_detail_bgc{
        background: linear-gradient(90deg, #369EFC 0%, #0084FC 100%);
    }
    .show_detail_bgc2{
        background: linear-gradient(90deg, #E0BC93 0%, #D0A365 100%);
    }
    .show_detail_bgc3{
        background: linear-gradient(90deg, #FF5A5A 0%, #FA2929 100%);
    }
    

    // 全部课程分类
    .all_course_categary{
        position: absolute;
        top:0px;
        left:16%;
        height: 680px;
        width: 220px;
        z-index: 99;
    }
    .course_categary{
        width: 208px;
        height: 680px;
        background: rgba(0, 0, 0, .09);
        position: relative;
        .wrap{
            padding:27px 0px 0px 14px;
        }   
    }
    .course_categary_title{
        display: flex;
        font-size: 16px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 24px;
        .icon{
            width: 20px;
            height: 16px;
            margin-right:20px;
            img{
                width: 20px;
                height: 16px;
            }
        }
    }
    .category_main{
        height: 580px;
        overflow: hidden;
    }
    .category_content{
        margin-top:19px;
        color: #FFFFFF;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        .con_title{
            font-weight: bold;
            margin-bottom:5px;
        }
        .con_text{
            display: flex;
            flex-wrap: wrap;
            font-size: 12px;
            line-height: 18px;
            .text_right{
                margin-right:16px;
                cursor: pointer;
            }
        }
    }
    // 底部查看更多
    .category_bottom{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 208px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, .1);
        font-size: 12px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
        img{
            width: 4px;
            height: 8px;
            margin-left: 7px;
        }
    }
    // 国家职业预约咨询
    .appointment{
        margin:20px 0px;
        height: 76px;
        line-height: 76px;
        background-image: url('../../assets/images/school/school_appointment_banner.png');
        background-size: 100% 100%;
        padding: 0 16%;
        .b_title{
            font-weight: bold;
        }
        .occupation{
            margin-left: 10px;
        }
        .right{
            width: 160px;
            height: 46px;
            border-radius: 23px;
            border: 1px solid #333333;
            cursor: pointer;
            img{
                width: 26px;
                height: 26px;
                margin-right:10px;
            }
        }
    }
    //合作院校
    .partnerSchool{
        background: #FFFFFF;
        padding: 0 16%;
        .dy_title{
            padding-top: 30px;
            width: 100%;
            margin-left:10px;
            margin-bottom:10px;

        }
        .titte_before{
            font-size: 26px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 39px;
        }
        .titte_before::before{
            content: '';
            display: inline-block;
            width: 4px;
            height: 23px;
            background: #EA5A41;
            position: relative;
            top:2px;
            left:-10px
        }
        .dy_more{
            font-size: 14px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            margin-right: 20px;
            img{
                width: 4px;
                height: 8px;
            }
        }
    }
    // 合作院校图片
    .partnerSchool_img{
        padding:47px 0px 52px;
        img{
            width: 100%;
        }
    }

}
@media screen and (max-width: 1560px){
    .trainingSclool{
        position: relative;
        width: 100%;
        min-width: 1200px;
        background: #fafafa;
        box-sizing: border-box;
    }
    // banner图片
    .top_banner{
        position: relative;
    }
    .el-carousel__item {
        img{
            width: 100%;
            height: 680px;
        }
    }
    // 轮播图名称
    .banner_title{
        position:absolute;
        top: 30%;
        left:calc(10% + 240px);
        .title_text{
            font-size: 50px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
        }
        .title_line{
            width: 32px;
            height: 3px;
            background: #FFFFFF;
            margin: 37px 0px;
        }
        .title_tips{
            font-size: 24px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 36px;
        }
    }
    // banner查看详情 
    .show_detail{
        position: absolute;
        bottom: 30%;
        left:calc(10% + 240px);
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 182px;
        height: 56px;
        border-radius: 4px;
        font-size: 24px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        cursor: pointer;
        color: #FFFFFF;
        z-index: 1000000;
        img{
            width: 22px;
            height: 22px;
            margin-left:16px;
        }
    }
    .show_detail_bgc{
        background: linear-gradient(90deg, #369EFC 0%, #0084FC 100%);
    }
    .show_detail_bgc2{
        background: linear-gradient(90deg, #E0BC93 0%, #D0A365 100%);
    }
    .show_detail_bgc3{
        background: linear-gradient(90deg, #FF5A5A 0%, #FA2929 100%);
    }
    // 全部课程分类
    .all_course_categary{
        position: absolute;
        top:0px;
        left:10%;
        height: 680px;
        width: 220px;
        z-index: 99;
    }
    .course_categary{
        width: 208px;
        height: 680px;
        background: rgba(0, 0, 0, .09);
        position: relative;
        .wrap{
            padding:27px 0px 0px 14px;
        }   
    }
    .course_categary_title{
        display: flex;
        font-size: 16px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 24px;
        .icon{
            width: 20px;
            height: 16px;
            margin-right:20px;
            img{
                width: 20px;
                height: 16px;
            }
        }
    }
    .category_main{
        height: 580px;
        overflow: hidden;
    }
    .category_content{
        margin-top:19px;
        color: #FFFFFF;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        .con_title{
            font-weight: bold;
            margin-bottom:5px;
        }
        .con_text{
            display: flex;
            flex-wrap: wrap;
            font-size: 12px;
            line-height: 18px;
            .text_right{
                margin-right:16px;
                cursor: pointer;
            }
        }
    }
    // 底部查看更多
    .category_bottom{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 208px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, .1);
        font-size: 12px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
        img{
            width: 4px;
            height: 8px;
            margin-left: 7px;
        }
    }
    // 国家职业预约咨询
    .appointment{
        margin:20px 0px;
        height: 76px;
        line-height: 76px;
        background-image: url('../../assets/images/school/school_appointment_banner.png');
        background-size: 100% 100%;
        padding: 0 10%;
        .b_title{
            font-weight: bold;
        }
        .occupation{
            margin-left: 10px;
        }
        .right{
            width: 160px;
            height: 46px;
            border-radius: 23px;
            border: 1px solid #333333;
            cursor: pointer;
            img{
                width: 26px;
                height: 26px;
                margin-right:10px;
            }
        }
    }
    //合作院校
    .partnerSchool{
        background: #FFFFFF;
        padding: 0 10%;
        .dy_title{
            padding-top: 30px;
            width: 100%;
            margin-left:10px;
            margin-bottom:10px;

        }
        .titte_before{
            font-size: 26px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 39px;
        }
        .titte_before::before{
            content: '';
            display: inline-block;
            width: 4px;
            height: 23px;
            background: #EA5A41;
            position: relative;
            top:2px;
            left:-10px
        }
        .dy_more{
            font-size: 14px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            margin-right: 20px;
            img{
                width: 4px;
                height: 8px;
            }
        }
    }
    // 合作院校图片
    .partnerSchool_img{
        padding:47px 0px 52px;
        img{
            width: 100%;
        }
    }
}
</style>